<template>
  <div class="paper" style="overscroll-behavior: auto;">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb>
        <el-breadcrumbItem>试卷管理</el-breadcrumbItem>
        <el-breadcrumbItem :to="'/manage/paper/list/' + $route.query.bankId">试卷列表</el-breadcrumbItem>
        <el-breadcrumbItem>手动出卷</el-breadcrumbItem>
      </el-breadcrumb>
    </div>

    <div class="Man-body">
      <div class="Manuleft">
        <div class="title">
          <ul class="ul-list">
            <li>
              <span>筛选</span>
            </li>
            <li>
              <span :style="tagId === 1 ? 'color: #568FEB' : ''" @click="filterCondition(1)">知识点</span>
              <span :style="tagId === 2 ? 'color: #568FEB' : ''" @click="filterCondition(2)">标签</span>
              <span :style="tagId === 3 ? 'color: #568FEB' : ''" @click="filterCondition(3)">题库</span>
            </li>
          </ul>
        </div>
        <hr class="hrStyle" />
        <div class="tree">
          <TreeList ref="tree" @questionBankId="getBankId" :type="tagId" />
        </div>
      </div>

      <div class="Manuright">
        <div class="search-bar flexCenter">
          <Input
            v-model="searchContent"
            style="width: 350px;"
            placeholder="请输入搜索关键字"
            search
            @on-search="getQuestion"
            @keyup.enter.native="getQuestion"
          />
          <div style="cursor:pointer;" @click="openDrawer">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-jiantou" />
            </svg>
            <span style="margin-left: 12px;color: #568FEB;"
              >已选题&nbsp;&nbsp;<span style="font-size: 18px">{{ pureQuestionList.length }}</span></span
            >
          </div>
        </div>

        <div class="topicList">
          <ul class="ul-list">
            <li class="flexCenter">
              <div style="font-size: 16px;">题目</div>
              <div style="text-align: right">
                <span>共计 :&nbsp;{{ questionList.length }} 道题</span>
                <Button
                  v-if="!isAllSelected && questionList.length !== 0"
                  type="primary"
                  @click="
                    handleSelectAll({
                      questionList,
                      isClassify: canCancel,
                      currentI: getEditorData.currentIndex
                    })
                  "
                  >全选</Button
                >
                <Button v-if="isAllSelected" class="selected" @click="handleUnselectAll(questionList)">取消全选</Button>
              </div>
            </li>
            <li style="height: calc(100vh - 245px);overflow-y: auto;overflow-x: hidden;">
              <div class="topicLi" v-for="(question, index) in questionList" :key="'question-' + index">
                <div>[{{ typeByquestionType(question.questionType) }}]</div>
                <div style="display: flex;justify-content: space-between;">
                  <pre class="htmlStyle" v-html="removeHtml(question.stem)" style="margin: 0;white-space: pre-wrap;padding-right: 45px;" />
                  <p style="white-space: nowrap">
                    <span style="margin-right: 20px"
                      >难度:&nbsp;{{ question.difficulty === 0 ? '简单' : question.difficulty === 1 ? '普通' : '困难' }}</span
                    >
                    <span>
                      <Button
                        v-if="!_isSelected(question.id)"
                        type="primary"
                        @click="
                          handleSelectQuestion({
                            question,
                            isClassify: getEditorData.isClassify,
                            currentI: getEditorData.currentIndex
                          })
                        "
                        >选题</Button
                      >
                      <Button v-else class="selected" @click="handleSelectQuestion({ question })">移除</Button>
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="page" style="display: flex; justify-content: space-between; align-items: center; white-space: nowrap;">
          <div style="width: 100%;margin-right: 10px" v-if="paginationBoxReflow">
            <Page
              class="yt-page"
              :class="'yt-page-' + (topicTotal.toString().length > 1 ? topicTotal.toString().length : 2)"
              style="margin-top: 0"
              show-sizer
              show-total
              show-elevator
              :total="topicTotal"
              :page-size-opts="[10, 20, 40, 100]"
              @on-page-size-change="changeSize"
              :current="pageNumber + 1"
              @on-change="changePage"
            />
          </div>
          <div @click="toPreViewExam" style="margin-right: 20px">
            <Button type="primary">预览试卷</Button>
          </div>
        </div>
      </div>

      <Drawer class="drawerModal" width="380" v-model="openExamBasket" :mask-closable="true">
        <div style="height: 100%;display: flex;flex-direction: column;justify-content: center">
          <div class="drawer">
            <div class="title" @click="closeDrawer()">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-jiantou1" />
              </svg>
              <span style="margin-left: 12px;color: #568FEB;"
                >已选题&nbsp;&nbsp;<span style="font-size: 18px">{{ pureQuestionList.length }}</span></span
              >
            </div>
            <div v-if="!IscancelClassify" style="margin-right: 12px;">
              <span style="margin-right: 20px" :style="canCancel === false ? 'color: #568FEB' : ''" @click="changeType(0)">全部</span>
              <span :style="canCancel === true ? 'color: #568FEB' : ''" @click="changeType(1)">题型</span>
            </div>
            <div v-else style="margin-right: 12px">
              <span style="color: #568FEB">题型</span>
            </div>
          </div>
          <div class="body">
            <PaperByQuestionType v-if="canCancel" />
            <PaperByAll v-else />
          </div>
          <div class="footer flexCenter">
            <Button @click="toPreViewExam" type="primary">预览试卷</Button>
          </div>
        </div>
      </Drawer>
    </div>
  </div>
</template>

<script>
import TreeList from './PaperType'
import question from '../../../../../components/common/old/questionOld'
import PaperByAll from './PaperByAll'
import PaperByQuestionType from './PaperByQuestionType'
import vuescroll from 'vuescroll'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { questionType } from '@/util/questionType'
export default {
  name: 'PaperEditor',
  components: { PaperByQuestionType, PaperByAll, TreeList, vuescroll },
  data() {
    return {
      paginationBoxReflow: true,
      topicTotal: 0,
      questionList: [],
      selectedTopicList: [],
      pageNumber: 0,
      AllSlec: false,
      knownLedgeList: [],
      tagId: 1,
      openExamBasket: false,
      questionType: 1,
      pageSize: 10,
      bankId: 0,
      type: false,
      searchContent: '',
      IsUpdate: false
    }
  },
  watch: {
    selectedQuestionList(val) {
      let questionLength = 0
      val.forEach(v => {
        questionLength += v.questionVOS.length
      })
      if (questionLength === 0) {
        this.$message.warning('请先选择题目...')
        this.$store.commit('question/setUpdateByQuestion', false)
        this.canCancel = false
        this.closeDrawer()
      }
    }
  },
  mounted() {
    window.scrollTo(0, 300)
    this.IsUpdate = this.$store.state.question.IsUpdateQuestionType
    if (this.$route.query.IsEdit) {
      // 修改试卷
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
        sessionStorage.removeItem('store')
      }
      // 防止页面刷新，存取vuex数据
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      })
    }
  },
  computed: {
    ...mapState('question', ['selectedQuestionList']),
    ...mapGetters('question', ['questionTypeStatistic', 'pureQuestionList', 'getEditorData', 'getUpdateByQuestion']),
    IscancelClassify: {
      // 是否使用自动分类
      get() {
        return this.getUpdateByQuestion
      }
    },
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    },
    canCancel: {
      get() {
        return this.getEditorData.isClassify
      },
      set(val) {
        this.setEditorData({
          isClassify: val
        })
      }
    },
    isAllSelected() {
      let allSelected = true
      if (this.questionList.length === 0) return false
      for (let i = 0; i < this.questionList.length; i++) {
        let question = this.questionList[i]
        let index = this.pureQuestionList.findIndex(selectedQuestion => selectedQuestion.id === question.id)
        if (index === -1) {
          allSelected = false
          break
        }
      }
      return allSelected
    }
  },
  methods: {
    ...mapMutations('question', ['handleSelectQuestion', 'handleSelectAll', 'handleUnselectAll', 'setEditorData']),
    getBankId(bankId, type) {
      this.type = type
      this.bankId = bankId
      this.pageNumber = 0
      this.pageSize = 10
      this.paginationBoxReflow = false
      this.$nextTick(() => {
        this.paginationBoxReflow = true
        this.getQuestion(type)
      })
    },
    _isSelected(questionId) {
      return this.pureQuestionList.findIndex(question => question.questionId === questionId) !== -1
    },
    closeDrawer() {
      this.questionType = 1
      this.openExamBasket = false
    },
    getQuestion(type) {
      if (this.searchContent.trim() !== '') {
        this.pageNumber = 0
      }
      const questionBankId = this.bankId
      const page = this.pageNumber
      const size = this.pageSize
      if (!this.type) {
        question.searchQuestionsWithAnswer(questionBankId, page, size, this.searchContent).then(res => {
          this.sortQuestions(res)
        })
      } else {
        let searchContent = this.searchContent
        let sort = ''
        question.searchQuestionsByKnownOrTagId(questionBankId, searchContent, sort, page, size, this.tagId).then(res => {
          this.sortQuestions(res)
        })
      }
    },
    sortQuestions(res) {
      this.topicTotal = res.res.total
      this.questionList = res.res.data
      this.questionList.map(item => {
        // 三个接口 传的字段不一样 做一个if判断
        if (!item.questionId) {
          item.questionId = item.id
        } else {
          item.id = item.questionId
        }
        item.stem = this.cleanWord(item.stem)
      })
    },
    removeHtml(str) {
      let b = ''
      if (str) {
        b = this.cleanWord(str)
      }
      // str.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')
      return b
    },
    typeByquestionType(type) {
      switch (type) {
        case 0:
          return '判断题'
        case 1:
          return '单选题'
        case 2:
          return '多选题'
        case 3:
          return '填空题'
        case 4:
          return '简答题'
        case 5:
          return '代码题'
      }
    },
    changeType(type) {
      if (type === 0) {
        this.canCancel = true
        this.cancelClassify()
      } else {
        this.canCancel = false
        this.classify()
      }
    },
    cancelClassify() {
      let a = []
      this.selectedQuestionList.map(item => {
        item.questionVOS.map(q => {
          a.push(q)
        })
      })
      let obj = {
        name: '默认大题',
        questionVOS: a
      }
      let arr = [obj]
      this.selectedQuestionList = arr
      this.resetTotalScore()
      this.canCancel = false
    },
    classify() {
      let map = {}
      let dest = []
      for (let i = 0; i < this.selectedQuestionList[0].questionVOS.length; i++) {
        let ai = this.selectedQuestionList[0].questionVOS[i]
        if (!map[ai.questionType]) {
          dest.push({
            name: questionType[ai.questionType].label,
            questionVOS: [ai]
          })
          map[ai.questionType] = ai
        } else {
          for (let j = 0; j < dest.length; j++) {
            let dj = dest[j]
            if (dj.name === questionType[ai.questionType].label) {
              dj.questionVOS.push(ai)
              break
            }
          }
        }
      }
      let compare = (x, y) => {
        let val1 = x.questionVOS[0].questionType
        let val2 = y.questionVOS[0].questionType
        if (val1 < val2) {
          return -1
        } else if (val1 > val2) {
          return 1
        } else {
          return 0
        }
      }
      let all = dest.sort(compare)
      all.map(item => {
        this.addClassification(item.name, item.questionVOS)
      })
      let arr = this.selectedQuestionList.slice()
      arr.splice(0, 1)
      this.selectedQuestionList = arr
    },
    addClassification(name, questionVOS) {
      const newSectionList = this.selectedQuestionList.concat({
        name,
        questionVOS
      })
      this.selectedQuestionList = newSectionList
      this.resetTotalScore()
      this.canCancel = true
    },
    resetTotalScore() {
      this.selectedQuestionList.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
    },
    openDrawer() {
      if (this.pureQuestionList.length === 0) {
        return this.$message.warning('请选择题目..')
      }
      // if (!this.IscancelClassify) {
      //   this.cancelClassify()
      // }
      this.openExamBasket = true
    },
    filterCondition(type) {
      this.tagId = type
      // this.getTreeDataByType()
    },
    changeSize(size) {
      this.pageSize = size
      this.getQuestion()
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.getQuestion()
    },
    toPreViewExam() {
      if (this.selectedQuestionList.length === 0) {
        return this.$message.warning('请先选择题目...')
      }
      this.$router.push({
        path: '/manage/paper/preview',
        query: {
          bankId: this.$route.query.bankId,
          IsEdit: this.$route.query.IsEdit,
          paperId: this.$route.query.paperId
        }
      })
    }
  },
  destroyed() {
    this.selectedTopicList = []
    this.$store.commit('question/setUpdateByQuestion', false)
    // localStorage.removeItem('paper')
  }
}
</script>

<style lang="less" scoped>
.flexCenter {
  min-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ul-list {
  list-style: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.paper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #333;
  width: 100%;
  overflow-x: auto;
  .bread {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px 0;
    height: 35px;
    ::v-deep .ivu-breadcrumb {
      font-size: 14px;
      color: #3a4e64;
      line-height: 28px;
    }
  }
  .Man-body {
    display: flex;
    height: 100%;
    //max-height: calc(100% - 55px);
    width: 100%;
    .Manuleft {
      background: white;
      width: 300px;
      min-width: 300px;
      height: 100%;
      margin-right: 12px;
      box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.16);
      .title {
        height: 55px;
        li {
          text-align: left;
          padding: 0 0 0 20px;
          font-size: 12px;
          &:first-child {
            width: 45%;
            font-size: 16px;
          }
          span {
            cursor: pointer;
            margin-right: 20px;
          }
        }
      }
      .hrStyle {
        height: 1px;
        background: #d2d2d2;
        border: none;
      }
    }
    .Manuright {
      width: calc(100% - 300px);
      overflow: auto;
      overflow-y: hidden;
      max-height: 100%;
      background: white;
      flex: auto;
      box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.16);
      .search-bar {
        display: flex;
        justify-content: space-between;
        padding: 0 21px;
        height: 55px;
        background: #fff;
        ::v-deep .ivu-input {
          border: 0;
          border-bottom: 1px solid #d2d2d2;
          border-radius: 0;

          &:focus {
            box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
          }
        }
      }
      .topicList {
        width: 100%;
        height: calc(100vh - 190px);
        max-height: calc(100vh - 190px);
        ul {
          display: flex;
          flex-direction: column;
          li {
            width: 100%;
            height: 72px;
            &:first-child {
              color: #333;
              height: 60px;
              display: flex;
              background: #e4e9ef;
              div {
                padding: 0 20px 0 30px;
                width: 50%;
                text-align: left;
                button {
                  margin-left: 20px;
                }
              }
            }
          }
          .topicLi {
            max-width: 100%;
            padding: 10px 20px;
            display: flex;
            /*height: 80px;*/
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            border-bottom: 1px solid #2e9afe;
            div {
              width: 100%;
              text-align: left;
            }
            p {
              /*width: 450px;*/
            }
            div:last-child {
              p {
                /*width: 50%;*/
              }
            }
          }
          .selected {
            background: #bdc3cb;
            color: #fff;
            box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.3);
            border: 1px solid #bdc3cb;
            &:hover {
            }
          }
        }
        .selected {
          color: #fff;
          border: 0;
          background: #bdc3cb;
        }
      }
      .page {
        height: 50px;
        padding-left: 20px;
        margin: 10px 0;
      }
    }
  }
}
::v-deep .ivu-drawer-close {
  display: none;
}
.drawer {
  height: 50px;
}
.body {
  height: calc(100% - 80px);
  flex: auto;
}
.footer {
  height: 55px;
  button {
    width: 180px;
    height: 32px;
    border-radius: 3px;
  }
}
.htmlStyle {
  max-width: 400px;
  /deep/ img {
    max-width: 640px;
    max-height: 450px;
  }
}
</style>
