var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"treeDiv",style:({ height: _vm.th + 'px' })},[_c('el-tree',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 1),expression:"type === 1"}],ref:"KTree",attrs:{"highlight-current":"","node-key":"id","data":_vm.knownLedgeList},on:{"node-click":_vm.getTopicByKnownLedge},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(_vm._s(data.name))]),_c('div',{staticStyle:{"color":"#568FEB","margin-left":"8px"}},[_vm._v("("+_vm._s(data.questionCount || 0)+")")])])}}])}),_c('el-tree',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 2),expression:"type === 2"}],ref:"TTree",attrs:{"highlight-current":"","node-key":"id","data":_vm.tagList},on:{"node-click":_vm.getTopicByKnownLedge},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(_vm._s(data.name))]),_c('div',{staticStyle:{"color":"#568FEB","margin-left":"8px"}},[_vm._v("("+_vm._s(data.questionCount || 0)+")")])])}}])}),_c('el-tree',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 3),expression:"type === 3"}],ref:"BTree",attrs:{"highlight-current":"","node-key":"questionBankId","data":_vm.bankList},on:{"node-click":_vm.getTopicByKnownLedge},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(_vm._s(data.name))]),_c('div',{staticStyle:{"color":"#568FEB","margin-left":"8px"}},[_vm._v("("+_vm._s(data.questionCount || 0)+")")])])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }