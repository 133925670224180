<template>
  <!--  <vuescroll :ops="ops">-->
  <div class="paperByAll">
    <vuescroll :ops="ops">
      <div v-for="(section, qIndex) in selectedQuestionList" :key="'section' + qIndex">
        <div v-for="(question, index) in section.questionVOS" :key="'question-' + index">
          <div class="topicAll">
            <span class="topicStem">{{ index + 1 }}、<span v-html="removeHtml(question.stem)"/></span>
            <div class="flexAliCenter" @mouseenter="IsdeleByIndex = 'dele+' + index" @mouseleave="IsdeleByIndex = ''">
              <JYImg
                v-if="IsdeleByIndex !== 'dele+' + index"
                :Url="require('../../../../../assets/delete.png')"
                @click="delQuestion(index, qIndex)"
              />
              <JYImg v-else :Url="require('../../../../../assets/deleteH.png')" @click="delQuestion(index, qIndex)" />
            </div>
          </div>
        </div>
      </div>
    </vuescroll>
  </div>
  <!--  </vuescroll>-->
</template>

<script>
import vuescroll from 'vuescroll'
import JYImg from '../../../../common/JYImg'
export default {
  name: 'PaperByAll',
  components: { vuescroll, JYImg },
  props: {
    list: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#333',
          opacity: 0.6 // 滚动条透明度
        }
      },
      value: this.list,
      IsdeleByIndex: ''
    }
  },
  computed: {
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    }
  },
  methods: {
    delQuestion(index, qIndex) {
      let a = JSON.parse(JSON.stringify(this.selectedQuestionList))
      a[qIndex].questionVOS.splice(index, 1)
      a.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
      this.selectedQuestionList = a
    },
    removeHtml(input) {
      let stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g
      let output = input.replace(stringStripper, ' ')
      // 2. strip Word generated HTML comments
      let commentSripper = new RegExp('<!--(.*?)-->', 'g')
      output = output.replace(commentSripper, '')
      let tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi')
      // 3. remove tags leave content if any
      output = output.replace(tagStripper, '')
      // 4. Remove everything in between and including tags '<style(.)style(.)>'
      let badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript']

      for (let i = 0; i < badTags.length; i++) {
        tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi')
        output = output.replace(tagStripper, '')
      }
      // 5. remove attributes ' style="..."'
      let badAttributes = ['style', 'start']
      for (let i = 0; i < badAttributes.length; i++) {
        let attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi')
        output = output.replace(attributeStripper, '')
      }
      return output.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')
    }
  }
}
</script>

<style lang="less" scoped>
.paperByAll {
  width: 100%;
  height: 100%;
  font-size: 13px;
  line-height: 20px;
  color: #666;
  font-weight: 400;
  /*overflow-x: hidden;*/
  .topicAll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .topicStem {
    width: 245px;
    display: flex;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .iconStyle {
    font-size: 17px;
    cursor: pointer;
  }
}
</style>
